:root {
  --light: 300;
  --regular: 400;
  --medium: 500;
  --bold: 600;
  --extra-bold: 700;
  font-family: "Poppins", serif;
}

body {
  line-height: 1.35 !important;
  font-family: "Poppins", serif;
}

* {
  margin: 0;
  padding: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

h1 {
  font-size: 22px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 16px;
}

h5 {
  font-size: 14px;
}

h6 {
  font-size: 14px;
}

/* Start of styles for react-split-pane */
.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  position: relative;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
/* End of styles for react-split-pane */

div#scrollable-auto-tabpanel-0,
div#scrollable-auto-tabpanel-1,
div#scrollable-auto-tabpanel-2 {
  height: calc(100% - 48px);
  width: 100%;
}

div#scrollable-auto-tabpanel-0 > div.MuiBox-root,
div#scrollable-auto-tabpanel-1 > div.MuiBox-root,
div#scrollable-auto-tabpanel-2 > div.MuiBox-root {
  position: relative;
  height: 100%;
  max-height: 100%;
  width: 100%;
}

/*
tr th:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

tr td:first-child{
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

tr td:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}*/

.MuiTableCell-root {
  border-bottom-color: rgba(224, 224, 224, 0.25) !important;
  padding: 6px 16px !important;
}

.MuiTab-root {
  min-width: 130px !important;
  max-width: 315px !important;
  background: #ffffff10 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #fff !important;
  background: #fff2 !important;
}

.MuiSnackbarContent-root {
  color: #fff !important;
}

.MuiDataGrid-root {
  color: #fff !important;
  border-color: rgb(128, 128, 128) !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader,
.MuiDataGrid-root .MuiDataGrid-cell {
  outline: none !important;
}

.MuiSvgIcon-root {
  color: #fff !important;
}

.MuiDataGrid-row.Mui-selected,
.MuiTableRow-root.Mui-selected {
  background: #0078d7 !important;
}
.MuiDataGrid-row.Mui-selected:hover,
.MuiTableRow-root.Mui-selected:hover {
  /*background: lighten(#0078d7, 0.65) !important;*/
  background: #1088e7 !important;
}

/*
table td:last-child {
  width: 100% !important;
}*/

.MuiDataGrid-cell {
  border-color: rgb(128, 128, 128) !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  white-space: normal !important;
  word-wrap: break-word !important;
}

.MuiDataGrid-columnHeaders {
  background: #909090 !important;
}

.Mui-odd {
  background: #3c3d3f !important;
}

.Mui-even {
  background: #515154 !important;
}

.MuiDataGrid-row:hover,
.MuiListItem-root:hover,
.MuiTableRow-root:hover,
.MuiTab-root:hover {
  background: #fff3 !important;
}

.MuiFilledInput-root {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.MuiFormLabel-root {
  color: #fff !important;
}

.MuiSelect-icon {
  fill: #fff !important;
}

.MuiListItem-root.Mui-selected {
  /*background: rgb(51, 147, 223) !important;*/
  /*background: rgb(0, 120, 215) !important;*/
  background: #0078d7 !important;
}
.MuiListItem-root.Mui-selected:hover {
  /*background: rgb(20, 140, 235) !important;*/
  /*background: lighten(#0078d7, 0.65) !important;*/
  background: #1088e7 !important;
}

.tabPanel {
  background: rgba(255, 255, 255, 0.09) !important;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(255, 255, 255, 0.46) !important;
  background-color: rgba(255, 255, 255, 0.12) !important;
}

.MuiToggleButton-root {
  color: rgba(0, 0, 0, 0.58) !important;
}

.MuiToggleButton-root.Mui-selected {
  color: #fff !important;
}

.secondaryText {
  color: rgba(255, 255, 255, 0.65);
}

.wait,
.wait * {
  cursor: wait !important;
}

.showAfterHalfSecond {
  animation: 0.5s showAfterAnim;
}

@keyframes showAfterAnim {
  0% {
    visibility: hidden;
  }
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

/* This removes the background color for un/pw auto-filled values */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: #ffffff;
}
